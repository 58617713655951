import React, { useEffect, useState } from "react"

import { Header } from "./header";

interface Props {
    title: string,
    children?: any
    location: Location,
}

const Layout = ({ title, children, location }: Props) => {

    const [darkMode, setDarkMode] = useState(() => {
        if (typeof window !== `undefined`) {
            return JSON.parse(window.localStorage.getItem('darkMode') || 'null')
        }

        return false
    })

    const [theme, setTheme] = useState('light')

    const darkModeInSystemOS = () => {
        if (typeof window !== `undefined` && (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            return true;
        }
        return false;
    }

    const checkThemeChange = () => {
        if (typeof document !== `undefined`) {
            if (darkMode === null && darkModeInSystemOS()) {
                document.documentElement.classList.add('mode-dark');
                setTheme('dark')
            } else if (darkMode) {
                document.documentElement.classList.add('mode-dark');
                setTheme('dark')
            } else {
                document.documentElement.classList.remove('mode-dark');
                setTheme('light')
            }
        }
    }

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);

        window.localStorage.setItem('darkMode', (!darkMode).toString());
    }

    const resetTheme = () => {
        window.localStorage.removeItem('darkMode');
        setDarkMode(null);
    }

    // DidMount and UnMount
    useEffect(() => {
        checkThemeChange();

        const browserSupport = typeof window !== `undefined` && (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)'))

        if (browserSupport) {
            window.matchMedia("(prefers-color-scheme: dark)").addListener(checkThemeChange)
        }

        return function cleanup() {
            if (browserSupport) {
                window.matchMedia("(prefers-color-scheme: dark)").removeListener(checkThemeChange)
            }
        }
    })

    return (
        <div className="flex flex-col font-body min-h-screen w-full text-gray-700 bg-white dark:bg-gray-900 dark:text-dark-primary relative">

            <div className="absolute right-0 top-0 mr-4 mt-4">

                <button className=" text-gray-900 dark:text-dark-primary" onClick={toggleDarkMode} title="Dark-mode väljare">
                    {theme === 'dark' ? (
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-6 h-6"
                        >
                            <circle cx="12" cy="12" r="5"></circle>
                            <line x1="12" y1="1" x2="12" y2="3"></line>
                            <line x1="12" y1="21" x2="12" y2="23"></line>
                            <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                            <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                            <line x1="1" y1="12" x2="3" y2="12"></line>
                            <line x1="21" y1="12" x2="23" y2="12"></line>
                            <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                            <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                        </svg>
                    ) : (
                            <svg
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-6 h-6"
                            >
                                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
                            </svg>
                        )
                    }
                </button>
                {darkMode !== null && (
                    <button onClick={resetTheme} className="flex w-full justify-center" title="Reseta tema">
                        <svg viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-3 h-3"
                        >
                            <path d="M23 4v6h-6" />
                            <path d="M20.49 15a9 9 0 11-2.12-9.36L23 10" />
                        </svg>

                    </button>
                )
                }
            </div>

            <Header siteTitle={title} location={location} />
            <main className="mt-12 mx-auto max-w-4xl px-4 md:px-0">
                {children}
            </main>
            <footer className="text-center p-12 text-base">
                Skapad av Nicklas Jarnesjö i Kalmar med <span role="img" aria-label="heart" className="text-black">♥️</span>. Copyright {new Date().getFullYear()}.
            </footer>
        </div >
    )
}

export { Layout }