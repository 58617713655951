import React from "react"

interface Props {
    published: boolean
}

const DraftBadge = ({ published }: Props) => {
    return (
        <>
            {published !== true &&
                <span className="px-2 py-1 bg-gray-500 text-white rounded text-base ml-2 align-middle">Draft</span>
            }
        </>
    )
}

export { DraftBadge }