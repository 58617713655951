/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Props {
    description?: string,
    lang?: string,
    meta?: [],
    title: string,
    image?: string | null
}

function SEO({ description = ``, lang = `sv`, meta = [], title, image }: Props) {
    const { site } = useStaticQuery(
        graphql`
      query SeoQuery{
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            defaultImage
            social {
              twitter {
                author
                site
              }
            }
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const metaImage = image ? `${site.siteMetadata.siteUrl}${image}` : `${site.siteMetadata.siteUrl}${site.siteMetadata.defaultImage}`

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: metaImage
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.social.twitter.author,
                },
                {
                    name: `twitter:site`,
                    content: site.siteMetadata.social.twitter.site,
                },
                {
                    name: `twitter:image`,
                    content: metaImage
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    )
}

export { SEO }
