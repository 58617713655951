import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { SearchComponent } from "./serach/SearchComponent"

interface Props {
    siteTitle: string,
    location: Location
}

const Header = ({ siteTitle, location }: Props) => {

    const [searchShow, setSearchShow] = useState(false)
    const closeSearch = () => {
        setSearchShow(false)
    }

    const handleKeyDown = (e: any) => {
        if (e.shiftKey && e.keyCode === 55) { // 55 = /
            e.preventDefault()
            setSearchShow(true)
        }
    }

    // DidMount and UnMount
    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown, false);

        return function cleanup() {
            document.removeEventListener("keydown", handleKeyDown, false)
        }
    }, [])

    return (
        <header>
            {location.pathname === '/' ?
                (
                    <h1 className="text-5xl p-8 m-auto text-center">
                        <Link to={'/'} className="text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500" aria-label="Till startsidan">
                            {siteTitle}
                        </Link>
                    </h1>
                ) : (
                    <h2 className="text-5xl p-8 m-auto text-center">
                        <Link to={'/'} className="text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500" aria-label="Till startsidan">
                            {siteTitle}
                        </Link>
                    </h2>
                )
            }

            <div className="border-t border-b border-gray-300 px-4 dark:border-gray-700">

                <div className="max-w-2xl lg:max-w-4xl flex justify-between items-center center mx-auto">
                    <div className="flex-grow-0 w-16">
                        <div className="flex">

                            <ul className="flex flex-row items-center">
                                <li>
                                    <a href="https://twitter.com/minimalisera" className="text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500" aria-label="Länk till Twitter-konto">
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="w-4 h-4"
                                        >
                                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                        </svg>
                                    </a>
                                </li>

                                <li className="ml-4">

                                    <a href={'/rss.xml'} className="text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500" aria-label="Länk till RSS-feed">
                                        <svg
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="w-4 h-4"
                                        >
                                            <path d="M4 11a9 9 0 0 1 9 9"></path>
                                            <path d="M4 4a16 16 0 0 1 16 16"></path>
                                            <circle cx="5" cy="19" r="1"></circle>
                                        </svg>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <nav className="flex items-center justify-center flex-grow">
                        <ul className="flex mx-auto">
                            <li>
                                <Link
                                    className="flex self-center p-5 no-underline text-base font-medium text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500"
                                    activeClassName="" to={'/'}
                                >
                                    Hem
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="flex self-center p-5 no-underline text-base font-medium text-gray-700 hover:text-blue-500 dark:text-dark-primary dark-hover:text-blue-500"
                                    activeClassName="" to={'/om'}
                                >
                                    Om sidan
                            </Link>
                            </li>
                        </ul>
                    </nav>

                    <div className="flex-grow-0 w-16">
                        <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-4 h-4 text-gray-700 hover:text-blue-500 cursor-pointer dark:text-dark-primary dark-hover:text-blue-500 ml-auto"
                            onClick={() => setSearchShow(true)}
                        >
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                    </div>
                </div>
            </div>

            {
                searchShow &&
                <SearchComponent show={searchShow} onClose={closeSearch} />
            }
        </header>
    )
}

export { Header }
