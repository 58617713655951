import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link, navigate } from "gatsby";

import { useFlexSearch } from 'react-use-flexsearch'

interface Props {
    show: boolean,
    onClose: Function
}

const SearchComponent = ({ show, onClose }: Props) => {

    const data = useStaticQuery(graphql`
        query SearchComponentQuery{ 
            localSearchPosts {
                index
                store
            }
        }
    `)

    const { index, store } = data.localSearchPosts
    const [query, setQuery] = useState('')
    const [focusIndex, setFocusIndex] = useState(0)

    interface ResultProps {
        id: string,
        slug: string,
        title: string
    }
    const result: ResultProps[] = useFlexSearch(
        query,
        index,
        JSON.parse(store)
    )

    const keyDownHandler = (e: any) => {
        if (e.keyCode === 27) { // Esc
            onClose(true)
        }

        if (e.keyCode === 40) { // ArrowDown
            e.preventDefault()
            if (focusIndex < (result.length - 1)) {
                setFocusIndex(focusIndex + 1)
            }
        }

        if (e.keyCode === 38) { // ArrowUp
            e.preventDefault()
            if (focusIndex > 0) {
                setFocusIndex(focusIndex - 1)
            }
        }

        if (e.keyCode === 13) { // Enter
            const focusedItem = result[focusIndex]

            if (focusedItem) {
                navigate(focusedItem.slug)

                if (decodeURIComponent(focusedItem.slug) === decodeURIComponent(window.location.pathname)) {
                    onClose(true)
                }
            }
        }
    }

    // DidMount and UnMount
    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler, false);

        return function cleanup() {
            document.removeEventListener("keydown", keyDownHandler, false)
        }
    })

    return (
        <div className={`w-full h-full fixed z-10 top-0 left-0 bg-white py-4 px-6 ${show ? 'block' : 'hidden'} dark:bg-gray-900`}>

            <div className="text-right">
                <button className="mb-6" onClick={() => onClose(true)}>Stäng</button>
            </div>

            <div className="w-full sm:w-2/3 lg:w-2/4 md:m-auto">

                <label htmlFor="searchInput">Sök på artiklar</label>
                <input
                    type="text"
                    id="searchInput"
                    autoComplete="off"
                    autoFocus
                    placeholder="Sök..."
                    onChange={(e) => setQuery(e.target.value)}
                    className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal dark:bg-gray-800 dark:border-gray-800"
                />

                {result.length > 0 &&
                    <div className="mt-4">
                        <h3>Resultat</h3>

                        <ul>
                            {result.map((post, index) => {
                                return <li key={post.id} className={`py-2 ${focusIndex === index ? 'bg-blue-100 px-4 dark:bg-gray-800' : ''}`}><Link to={post.slug}>{post.title}</Link></li>
                            })}
                        </ul>
                    </div>
                }
            </div>

        </div>
    )
}

export { SearchComponent }